.App {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(180deg, #CEDFFF 20%, #FFFFFF 65%);
  }
  
  .logo-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  .text-deltyo {
    color: #6600B7;
    font-size: 60px;
    font-weight: 600;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .footer-links a {
    color: #6600B7;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }