.legal-terms {
    height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, #CEDFFF 20%, #FFFFFF 65%);
  position: relative;

  .back-arrow {
    position: absolute;
    top: 30px;
    left: 30px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%) scaleX(-1);
    z-index: 1;
  }
}

.legal-terms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ECEFFF;
  border-radius: 24px;
  padding: 20px;
  gap: 20px;
  width: 80%;
  margin: 40px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .company-name, .contact-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.company-info, .contact-info {
    min-width: 300px;
    width: 45%;
    height: 45%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 24px;
    padding: 20px;
    position: relative;
    background-color: #FFFFFF;

    h2 {
      font-size: 24px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }

/* Media query pour les tablettes */
@media screen and (max-width: 768px) {
    .legal-terms-container {
        width: 90%;
        margin-top: 50px;
        padding: 10px;
    }

    .company-info {
        justify-content: flex-end;
        min-height: 250px;
    }

    .contact-info {
        justify-content: flex-end;
        min-height: 290px;
    }
}
