// Pour tout objet

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@font-face {
    font-family: 'MyriadProBlackSemiCondensedItalic';
    src: url('../../fonts/MyriadPro-BlackSemiCondensedItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

// Pour le body

body {
    font-family: 'MyriadProBlackSemiCondensedItalic', sans-serif;
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #fff;
}

// ::-webkit-scrollbar {
//     width: 7px;
//     color: black;
// }

// ::-webkit-scrollbar-track {
//     background: rgba(255, 255, 255, 0.6);
// }

// ::-webkit-scrollbar-thumb {
//     background: rgb(74, 74, 74);
//     border-radius: 50px;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: rgba(255, 255, 255, 1);
//     border-radius: 50px;
// }